<template>
  <div class="code">
    <topbar :title="new_HYM_huiYuanMa" color="#000"></topbar>
    <div class="vipCode">
      <div class="vipCodeLogo"></div>
      <p class="alert">{{new_HYM_chuShi}}</p>
      <div class="qrcode" ref="code"></div>
      <p class="vipCodeNum">{{new_HYM_huiyuanHaoMa}}{{ $store.state.userInfo.userId }}</p>
    </div>
    <div class="btn">
      <i></i>
      <p>{{new_HYM_huiYuanMa}}</p>
    </div>
    <div class="btn btn1" @click="chegePage()">
      <i></i>
      <p>{{new_HYM_jiFenDuiHuan}}</p>
    </div>
  </div>
</template>
<script>
import Topbar from "../../components/Topbar.vue";
import L from "../../assets/js/language";
import AraleQRCode from "arale-qrcode";

export default {
  name: "VipCode",
  data() {
    return {
      code: null,
      new_HYM_huiYuanMa: "",
      new_HYM_chuShi:"",
      new_HYM_huiyuanHaoMa:"",
      new_HYM_jiFenDuiHuan:""
    };
  },
  components: {
    Topbar,
  },
  methods: {
    createQRCode() {
      var code = new AraleQRCode({
        render: "png", // 生成的类型 'svg' or 'table'
        text: this.$store.state.userInfo
          ? this.$store.state.userInfo.userId + ""
          : "游客", // 需要生成二维码的链接
        size: 170, // 生成二维码大小
      });
      this.$refs.code.appendChild(code);
    },
    chegePage(){
      this.$router.push("/myScore/1")
    },
    setLanguage() {
      var T = "chinese";
      if (this.$store.state.language != 1) {
        T = "english";
      }
      this.new_HYM_huiYuanMa = L.new_HYM_huiYuanMa[T];
      this.new_HYM_chuShi = L.new_HYM_chuShi[T];
      this.new_HYM_huiyuanHaoMa=L.new_HYM_huiyuanHaoMa[T]
      this.new_HYM_jiFenDuiHuan=L.new_HYM_jiFenDuiHuan[T]
    },
  },
  created() {
    this.$nextTick(() => {
      this.setLanguage();
      this.createQRCode();
    });
  },
};
</script>
<style scoped>
.code {
  background-color: #b1b1b3;
  height: 100vh;
  box-sizing: border-box;
}
.vipCode {
  width: 3.43rem;
  height: 3.72rem;
  background: url("../../assets/icon/codeBg1.png") no-repeat center;
  background-size: cover;
  margin-top: 0.14rem;
  margin-left: 0.16rem;
  /* position: fixed;
  top: 50%;
  left: 0;
  transform: translateY(-50%); */
  border-radius: 0.12rem;
  overflow: hidden;
}
.vipCodeLogo {
  margin: 0.32rem auto 0.2rem;
  width: 0.8rem;
  height: 0.47rem;
  background: url("../../assets/icon/codelogo.png") no-repeat center;
  background-size: cover;
}
.qrcode {
  width: 1.7rem;
  height: 1.7rem;
  overflow: hidden;
  margin: 0.24rem auto 0;
}
.alert {
  font-family: PingFang HK;
  font-style: normal;
  font-weight: 600;
  font-size: 0.16rem;
  line-height: 0.22rem;
  color: #03060d;
}
.vipCodeNum {
  font-family: PingFang HK;
  font-style: normal;
  font-weight: 500;
  font-size: 0.14rem;
  line-height: 0.2rem;
  color: #808080;
  margin: 0 !important;
}
.btn {
  width: 3.43rem;
  height: 0.69rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: PingFang HK;
  font-style: normal;
  font-weight: normal;
  font-size: 0.16rem;
  line-height: 0.69rem;
  color: #e4022b;
  margin-left: 0.16rem;
  background-color: #fff;
  border-radius: 0.12rem;
  margin-top: 0.21rem;
}
.btn i {
  width: 0.22rem;
  height: 0.2rem;
  margin-right: 0.09rem;
  background: url("../../assets/icon/code2.png") no-repeat center;
  background-size: cover;
}
.btn1 {
  color: #b5b6bd;
  margin-top: 0.17rem;
}
.btn1 i {
  background: url("../../assets/icon/dhjy.png") no-repeat center;
  background-size: cover;
}
</style>