<template>
  <div class="topbarBox" @click="close">
    <div id="topbar" :style="{ 'background-color': bgColor }">
      <div class="leftIcon">
        <img v-if="left" src="@/assets/icon/left.png" alt="" />
      </div>
      <div :style="{ color: color }">{{ title }}</div>
      <div class="right" @click.prevent.stop="rightClick">
        <img v-if="rightType == 1" src="@/assets/icon/shoploca.png" alt="" />
        <span v-else-if="rightType == 2" class="rightBtn">{{ right }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Topbar",
  data() {
    return {
      fail: null,
      pageParam: null,
    };
  },
  props: {
    title: {
      type: String,
      default: " ",
    },
    left: {
      type: Boolean,
      default: true,
    },
    right: {
      type: String,
      default: "",
    },
    rightType: {
      type: Number,
      default: 0,
    },
    color: {
      type: String,
      default: "#1f2123",
    },
    bgColor: {
      type: String,
      default: "#fff",
    },
    appJoin: {
      type: Boolean,
      default: false,
    },
    category: {
      type: String,
      default: "",
    },
    backPage: {
      type: String,
      default: "",
    },
  },
  methods: {
    close() {
      if (this.category) {
        window.dataLayer.push({
          event: "back_page",
          page_category: this.category,
        });
      }
      if (this.fail) {
        if (this.pageParam && this.pageParam.app) {
          window.location = "kfchk://index";
        } else {
          this.$router.push("/");
        }
        return;
      }
      if (this.appJoin) {
        window.location = "kfchk://exith5";
        return;
      }
      if (this.backPage) {
        this.$router.push(this.backPage);
        return;
      }
      // this.$store.commit("chnageTransion", "slide-left");
      this.$router.back(-1);
    },
    rightClick() {
      this.$emit("rightClick");
    },
  },
  created() {
    if (
      this.$route.params &&
      this.$route.params.query &&
      JSON.parse(this.$route.params.query).fail
    ) {
      this.fail = JSON.parse(this.$route.params.query).fail;
      this.pageParam = JSON.parse(this.$route.params.query);
    }
  },
};
</script>

<style scoped lang="scss">
.topbarBox {
  height: 0.44rem;
}
#topbar {
  width: 100%;
  height: .44rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999999999999999999;
}
#topbar .leftIcon {
  width: 32px;
  height: 32px;
  overflow: hidden;
  position: relative;
  margin-left: 16px;
}
#topbar .leftIcon img {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
}
#topbar div:nth-child(2) {
  position: absolute;
  width: 100%;
  height: 48px;
  line-height: 48px;
  color: #1f2123;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
}
#topbar .right {
  height: 0.28rem;
  min-width: 0.28rem;
  overflow: hidden;
  position: relative;
  font-size: 0.14rem;
  font-family: PingFang HK;
  font-weight: 400;
  color: #1f2123;
  margin-right: 0.16rem;
}
#topbar .right img {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
}
.rightBtn {
  display: inline-block;
  // width: .86rem;
  height: 0.28rem;
  background: #fe1112;
  color: #fff;
  border-radius: 0.14rem;
  font-size: 0.14rem;
  line-height: 0.28rem;
  padding: 0 0.15rem;
  box-sizing: border-box;
}
</style>
